import deepmerge from 'deepmerge';
import type { Config, DeepPartial } from '../default/types';
import defaultConfig, { getFullLocaleFromConfig, environment, customArrayMerge } from '../default';
import { productConfig } from './product/product';
import { installmentCalculatorConfig } from './product/installmentCalculator';
import { customerRatingsConfig } from './product/customerRatings';
import { dynamicHelp } from './cms/dynamicHelp';

const tenantId = 'ottonl';

const config = deepmerge<Config, DeepPartial<Config>>(
  defaultConfig,
  {
    host: { name: tenantId, domain: 'otto.nl' },
    clientId: 'OttoNl',
    i18n: {
      defaultLocale: 'nl-NL',
      locales: ['nl-NL'],
      languages: ['nl'],
    },
    tenantId,
    company: {
      name: 'Otto',
      seoTitleName: 'OTTO',
    },
    timeZone: 'Europe/Amsterdam',
    headerSlots: {
      payback: false,
      serviceApi: `https://storage.googleapis.com/drupal-pages/${environment}/menus/${tenantId}/<locale>/bucket-service-flyout.json`,
    },
    search: {
      isInspiringSearchEnabled: false,
    },
    assets: {
      cmsImage: {
        imageOptimizationEnabled: true,
        uriTransformEndpoint: `https://storage.googleapis.com/drupal-images-${tenantId}/$1/`,
      },
      assetServerHost: 'https://assets.otto.nl',
    },
    staticContent: {
      apiEndpoints: {
        webappJson: `https://cms.${tenantId}.empirie.<envTld>/<locale>/<path>?_format=webapp_json&flat`,
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { cmsPage: ['storefront'] }, locale: 'de' },
          { params: { cmsPage: ['service-hilfe'] }, locale: 'de' },
        ],
      },
      cmsFontToTheme: {
        '.OttoSansBold, .font-normal-700': {
          fontWeight: 'fontWeightBold',
        },
        '.font-normal-700-highlighted': {
          fontFamily: 'var(--font-secondary)',
          fontWeight: 'fontWeightBold',
        },
        '.font-normal-300': {
          fontWeight: 'fontWeightLight',
        },
      },
    },
    product: productConfig,
    recommendations: {
      provider: 'empiriecom',
    },
    installmentCalculator: installmentCalculatorConfig,
    order: {
      paths: {
        ...defaultConfig.order.paths,
        payment: defaultConfig.order.paths.delivery,
        checkandorder: defaultConfig.order.paths.delivery,
      },
      premium: { enabled: false },
      accountInformation: {
        bankTransferEnabled: false,
        bicAvailable: false,
      },
      hasCo2Compensation: false,
      recommendations: {
        wishlist: {
          similarProducts: {
            isEnabled: false,
            type: 'searchReplace',
          },
        },
      },
    },
    footer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-pages/${environment}<subfolder>/${tenantId}/<locale>/<identifier>.json`,
      },
      ssg: {
        prerenderAtBuildTime: [
          { params: { type: ['full'] }, locale: 'de' },
          { params: { type: ['socket'] }, locale: 'de' },
        ],
      },
    },
    forms: {
      useOptimizely: true,
      newsletterSettings: {
        sendAdditionalSubscribe: true,
        useDOI: false,
        useApproval: true,
      },
      contact: { dynamicHelp },
      newsletterUnsubscribe: {
        useDedicatedSubmitButtonTitleFor: ['unsubscribe|199', 'unsubscribe|299', 'unsubscribe|99'],
        fields: [
          {
            id: 'newsletter',
            formType: 'radio',
            defaultValue: 'unsubscribe|199',
          },
        ],
      },
      newsletterSubscribe: {
        nl: {
          unsubscribeLink: 'https://www.otto.nl/klantenservice/otto-nieuwsbrief/afmelden',
          voucherLink: '',
        },
      },
      validation: {
        rules: {
          fileUpload: {
            acceptedFormats: 'image/*,.heic,.heif,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.rtf,.pdf,.txt',
            regexAcceptedUploadFormats:
              /\.(jpe?g|png|gif|webp|bmp|heic|heif|docx?|xlsx?|pptx?|rtf|pdf|txt)$/i,
          },
          postalCode: {
            regex: /^(\d{4}\s?[A-Za-z][A-Za-z])?$/, // dutch postcodes are like: "1234 AB". Postcode is optional.
            errorMessage: {
              id: 'forms.type.postalcode.error',
              defaultMessage: 'Vul alsjeblieft je postcode in',
            },
          },
        },
      },
    },
    customerRatings: customerRatingsConfig,
    banner: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    nlSheetTexts: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/nl-sheet.json`,
      },
    },
    serviceLayer: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<locale>/<identifier>.json`,
        bucketPages: `https://storage.googleapis.com/drupal-pages/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    seoContent: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/inspire-seofiles/<locale>_${tenantId}-<category>.json`,
      },
    },
    survey: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/drupal-surveys/${environment}/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    promotionBanner: {
      apiEndpoints: {
        bucketEmptyPromotions: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/empty-promotion.json`,
        bucketPromotion: `https://storage.googleapis.com/promotion-data/${tenantId}/<language>/<code>.json`,
        bucketContentSnippet: `https://storage.googleapis.com/drupal-contentsnippet/${environment}/${tenantId}/<language>/<identifier>.json`,
      },
    },
    voucher: {
      apiEndpoints: {
        bucket: `https://storage.googleapis.com/promotion-data/${tenantId}/<locale>/<identifier>.json`,
      },
    },
    appBanner: {
      appUrl: 'https://u9mk.adj.st/?adj_t=1l5yy7fp_1lf5nx6j&adj_deep_link=ottonlapp%3A%2F%2F',
    },
    insiderPushNotifications: {
      enabled: true,
      apiKey: '532e1d55740d5727a5bcb2635a0b67f1',
      partnerName: 'ottonl',
      partnerId: 10003609,
    },
    tracking: {
      soastaId: 'KEMAL-D958B-8GVEE-QV47M-2G9JS',
      tagmanagerId: 'GTM-W257QPH5',
      googleSiteVerificationID: ['eNom2VQv_nZ7v8uBGEiWx5EkuIAz9DI50aXmynBZ9B4'],
      msvalidateID: '30C653343D71C28456707D55556ED4BC',
      pinterestRichPinVerificationID: 'ac09bba0297ad24a1163ac4a42c3dfe4',
      schemaOrgOrganisation: {
        'nl-NL': {
          legalName: 'OTTO B.V.',
          addressCountry: 'NL',
          addressRegion: 'NB',
          addressLocality: 'Tilburg',
          areaServed: 'NL',
          description:
            'Otto Online Shop - vele merken, geweldige aanbiedingen en de beste service!',
          contactEmail: 'klantenservice@otto.nl',
          contactUrl: 'https://www.otto.nl/klantenservice',
          email: 'klantenservice@otto.nl',
          hoursAvailable: ['Mo-Fr 08:00-21:00', 'Sa 09:00-17:00'],
          logo: `/assets/logos/${tenantId}/logo.svg`,
          name: 'OTTO',
          productSupported: '',
          sameAs: [
            'https://www.facebook.com/ottonetherlands',
            'https://www.instagram.com/otto_nl',
            'https://nl.pinterest.com/otto_nl',
            'https://nl.wikipedia.org/wiki/Otto_B.V.',
          ],
          telephone: '+31-088-465 1555',
          url: 'https://www.otto.nl',
          postalCode: '5026 RT',
          privacyNoticePath: '/klantenservice/privacy',
          potentialAction: {
            target: 'https://www.otto.nl/s/',
          },
          streetAddress: 'Charles Stulemeijerweg 2',
          founder: 'Werner Otto',
          foundingDate: '1949-08-17',
          foundingLocation: 'Hamburg',
          memberOf: 'ottogroup',
          vatID: 'NL004677638B01',
        },
      },
    },
    externalScript: {
      kuecheCo: {
        key: '',
      },
    },
    headerInformation: {
      nl: {
        description:
          'OTTO: de online shop voor kleding, schoenen, meubels, elektronica en huishoudelijke apparaten. Direct online shoppen en in één keer of in termijnen betalen.',
        title: 'OTTO | Mode & lifestyle online shoppen bij OTTO',
        canonical: 'https://www.otto.nl',
        robots: 'index,follow',
      },
    },
    // kim is renamed as "customerCentricFeedback"
    customerCentricFeedback: {
      enabled: false,
      tenantId: '1000',
    },
    dynamicYieldTracking: {
      key: '',
    },
    geoCountryLayer: {
      enabled: false,
      flag: 'nl',
      alternatives: [
        {
          flag: 'de',
          shops: [{ link: 'https://www.otto.de' }],
        },
        {
          flag: 'at',
          shops: [{ link: 'https://www.ottoversand.at' }],
        },
        {
          flag: 'ch',
          shops: [{ link: 'https://www.jelmoli-shop.ch' }],
        },
        {
          flag: 'it',
          shops: [{ link: 'https://www.bonprix.it' }],
        },
      ],
    },
    priceFormat: 'Iso',
  },
  { arrayMerge: customArrayMerge },
);

export const getFullLocale = (localeOrLanguage: string | undefined) =>
  getFullLocaleFromConfig(localeOrLanguage, config);

// eslint-disable-next-line import/no-default-export
export default config;
